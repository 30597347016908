<template>
  <div class="fullwidth-carousel-container">

    <!-- Carousel Images -->
    <b-carousel
      id="carousel"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;"
    >

      <template v-for="item in data">
        <div :key="item.title">
          <b-carousel-slide>
            <template #img>
              <b-img-lazy
                class="d-block img-fluid w-100"
                width="1024"
                height="480"
                :src="item.image"
                alt="Image Slot"
                style="background-color: white"
              />
            </template>
          </b-carousel-slide>
        </div>
      </template>
    </b-carousel>
  </div>
</template>

<script>

import {
  BCarousel,
  BCarouselSlide,
  BImgLazy,
} from 'bootstrap-vue'

export default {
  name: 'ImageSlider',
  components: {
    BImgLazy,
    BCarousel,
    BCarouselSlide,
  },
  props: {
    data: {
      type: Array,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      slide: 0,
    }
  },
}
</script>
