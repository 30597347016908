<template>
  <div>
    <!-- Banner -->
    <banner
      :video="data.bannerVideo"
      :image="data.bannerImage"
      :data="data.banner"
      width="600"
      height="650"
    />

    <!-- Smart Solutions -->
    <smart-solutions :data="data.categories" />

    <!-- Services Section -->
    <our-competencies />

    <!-- Image Separator -->
    <image-separator :image="data.imageSeparator" />

    <!-- Our Clients -->
    <logo-slider
      :title="$t('clients.clientsTitle')"
      :subtitle="$t('clients.clientsSubtitle')"
      :data="data.ourClients"
    />

    <!-- Icon Separator -->
    <icon-separator />

    <!-- Work Teams -->
    <logo-slider
      :title="$t('clients.workTeamsTitle')"
      :subtitle="$t('clients.workTeamsSubtitle')"
      :desc="$t('clients.workTeamsDesc')"
      :data="data.workTeams"
    />

    <!-- PYMES -->
    <pymes />

    <!-- Colaborate -->
    <colaborate
      :title="$t('clients.colaborateTitle')"
      :image="data.colaborateImage"
    />
  </div>
</template>

<script>
import Banner from 'Components/Common/Banner.vue'
import SmartSolutions from 'Components/HomePage/SmartSolutions.vue'
import OurCompetencies from 'Components/HomePage/OurCompetencies.vue'
import ImageSeparator from 'Components/Common/ImageSeparator.vue'
import LogoSlider from 'Components/HomePage/LogoSlider.vue'
import IconSeparator from 'Components/HomePage/IconSeparator.vue'
import Pymes from 'Components/HomePage/Pymes.vue'
import Colaborate from 'Components/HomePage/Colaborate.vue'

import MyData from 'Data/dashboardone.json'

export default {
  name: 'Home',
  components: {
    Banner,
    SmartSolutions,
    OurCompetencies,
    ImageSeparator,
    LogoSlider,
    IconSeparator,
    Pymes,
    Colaborate,
  },
  data() {
    return {
      data: MyData.data,
    }
  },
}
</script>
