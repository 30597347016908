<template>
  <div
    class="mt-5 p-3"
    style="background-color: rgba(0,0,0,0.11) !important"
  >
    <div class="container">
      <b-row class="my-5 d-flex align-items-center justify-content-center">

        <!-- Description -->
        <b-col
          cols="12"
          xl="5"
          lg="5"
        >
          <h2
            class="mb-5 text-center"
            style="color: black !important; font-size: 20px;"
          >
            {{ $t('clients.pymesTitle') }}
          </h2>
        </b-col>

        <!-- Logos -->
        <b-col
          class="ml-xl-5 ml-lg-5 text-center"
          cols="12"
          xl="5"
          lg="5"
        >
          <b-img-lazy
            :src="data.cenelecImage"
            alt="Logo Cenelec Company"
          />

          <b-img-lazy
            :src="data.sbsImage"
            alt="Logo SBS Company"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import MyData from 'Data/dashboardone.json'

import {
  BRow,
  BCol,
  BImgLazy,
} from 'bootstrap-vue'

export default {
  name: 'Pymes',
  components: {
    BRow,
    BCol,
    BImgLazy,
  },
  data() {
    return {
      data: MyData.data,
    }
  },
}
</script>
