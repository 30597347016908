<template>
  <div class="image-separator d-flex align-items-center justify-content-center mt-5">

    <!-- Background Image -->
    <b-img-lazy
      :src="image"
      alt="Background Image"
      style="width: 100%; height: 300px; object-fit: cover;"
    />

  </div>
</template>

<script>
import { BImgLazy } from 'bootstrap-vue'

export default {
  name: 'ImageSeparator',
  components: {
    BImgLazy,
  },
  props: {
    image: {
      type: String,
      default: null,
      required: true,
    },
  },
}
</script>

<style scoped>

  .image-separator {
    height: 300px !important;
    width: 100% !important;
    position: relative;
  }

</style>
