<template>
  <div class="container my-5">

    <!-- Title -->
    <div class="row justify-content-center text-center">

      <h1 class="display-2 text-center">
        {{ title }}
        {{ subtitle }}
      </h1>

      <div class="w-100">

        <!-- Description -->
        <div class="block-head w-100 desc">
          <p class="lead head-desc">
            {{ desc }}
          </p>
        </div>

        <!-- Slider -->
        <div class="mt-5">
          <Flicking
            :options="{ circular: true, renderOnlyVisible: false, align: 'prev', panelsPerView: windowSize, circularFallback: 'bound' }"
            :plugins="plugins"
          >
            <div
              v-for="item in data"
              :key="item.title"
              class="flicking-panel mb-5"
            >
              <b-aspect aspect="200:71">
                <b-img-lazy
                  :src="item.image"
                  alt="Logo Image"
                />
              </b-aspect>
            </div>
            <div
              slot="viewport"
              class="flicking-pagination"
            />
          </Flicking>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import { Flicking } from '@egjs/vue-flicking'
import { Pagination, AutoPlay } from '@egjs/flicking-plugins'
import '@egjs/vue-flicking/dist/flicking.css'
import '@egjs/vue-flicking/dist/flicking-inline.css'
import '@egjs/flicking-plugins/dist/flicking-plugins.css'
import '@/assets/css/flicking-pagination.css'

import { BImgLazy, BAspect } from 'bootstrap-vue'

export default {
  name: 'LogoSlider',
  components: {
    Flicking,
    BImgLazy,
    BAspect,
  },
  props: {
    title: {
      type: String,
      default: null,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
      required: true,
    },
    desc: {
      type: String,
      default: null,
      required: false,
    },
    data: {
      type: Array,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      plugins: [new AutoPlay({ duration: 4000, direction: 'NEXT', stopOnHover: false }), new Pagination({ type: 'scroll' })],
      windowSize: 3,
    }
  },
  mounted() {
    this.onResize()
  },
  created() {
    window.addEventListener('resize', this.onResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      if (document.documentElement.clientWidth > 900) { this.windowSize = 3 } else { this.windowSize = 1 }
    },
  },
}
</script>

<style scoped>

  .flicking-panel {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flicking-viewport {
    transition: height 500ms;
  }
</style>
