<template>
  <div class="mt-4">

    <!-- Title -->
    <h1 class="display-2 text-center">
      {{ $t('digitalization.competenciesTitle') }}
      {{ $t('digitalization.competenciesSubtitle') }}
    </h1>

    <!-- Images -->
    <div class="container">
      <div class="row row-grid">
        <div
          v-for="competencies in data.competencies"
          :key="competencies.title"
          class="col-lg-3 text-center my-4"
        >
          <div class="img-lazy d-flex align-items-center justify-content-center category-box">

            <!-- Background Image -->
            <b-img-lazy
              :src="competencies.image"
              alt="Background Image"
              style="width: 100%; height:100%"
            />

            <!-- Over Text -->
            <div class="category-box-content">

              <h3>
                {{ competencies.title }}
              </h3>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyData from 'Data/dashboardone.json'

import { BImgLazy } from 'bootstrap-vue'

export default {
  name: 'OurCompetencies',
  components: { BImgLazy },
  data() {
    return {
      data: MyData.data,
    }
  },
}
</script>

<style scoped>

  .img-lazy {
    background-size: cover;
    position: relative;
  }

  .text {
    position: absolute;
  }

  h3 {
    color: #ff8f00 !important;
    font-size: 22px !important;
  }

</style>
