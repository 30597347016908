<template>
  <div
    id="id_cbm"
    class="mt-5 container"
  >

    <!-- Title and Desc -->
    <div class="w-100">
      <div class="mb-5">

        <h1 class="display-2 text-center mb-4">
          {{ $t('smartSolutions.smartSolutionsTitle') }}
        </h1>

        <p class="text-center">
          {{ $t('smartSolutions.smartSolutionsDesc') }}
        </p>

      </div>
    </div>

    <!-- Images Carousel -->
    <b-row class="my-5">
      <b-col
        class="d-flex align-items-center justify-content-center"
        xl="6"
      >
        <image-slider :data="data" />
      </b-col>

      <b-col
        class="d-flex align-items-center justify-content-center"
        xl="6"
      >
        <div
          id="typed-strings"
          class="d-none d-md-block"
        >
          <h4>
            Optimized
            <span class="typed-text">{{ typeValue }}</span>
            <span class="blinking-cursor">|</span>
            <span
              class="cursor"
              :class="{ typing: typeStatus }"
            >&nbsp;</span>
            to reach the farthest.
          </h4>
        </div>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import ImageSlider from 'Components/Common/ImageSlider.vue'

export default {
  name: 'SmartSolutions',
  components: {
    BRow,
    BCol,
    ImageSlider,
  },
  props: {
    data: {
      type: Array,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      displayTextArray: ['TECHNOLOGY', 'TEAM', 'INFRASTRUCTURES '],
      typeValue: '',
      typeStatus: false,
      typingSpeed: 100,
      erasingSpeed: 100,
      newTextDelay: 2000,
      displayTextArrayIndex: 0,
      charIndex: 0,
    }
  },
  created() {
    setTimeout(this.typeText, this.newTextDelay + 200)
  },
  methods: {
    typeText() {
      if (this.charIndex < this.displayTextArray[this.displayTextArrayIndex].length) {
        if (!this.typeStatus) this.typeStatus = true
        this.typeValue += this.displayTextArray[this.displayTextArrayIndex].charAt(
          this.charIndex,
        )
        this.charIndex += 1
        setTimeout(this.typeText, this.typingSpeed)
      } else {
        this.typeStatus = false
        setTimeout(this.eraseText, this.newTextDelay)
      }
    },
    eraseText() {
      if (this.charIndex > 0) {
        if (!this.typeStatus) this.typeStatus = true
        this.typeValue = this.displayTextArray[this.displayTextArrayIndex].substring(
          0,
          this.charIndex - 1,
        )
        this.charIndex -= 1
        setTimeout(this.eraseText, this.erasingSpeed)
      } else {
        this.typeStatus = false
        this.displayTextArrayIndex += 1
        if (this.displayTextArrayIndex >= this.displayTextArray.length) { this.displayTextArrayIndex = 0 }
        setTimeout(this.typeText, this.typingSpeed + 1000)
      }
    },
  },
}
</script>

<style scoped>

  .typed-strings {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h4 {
    color: black !important;
    font-size: 35px !important;
    span.typed-text {
      color: #ff8f00 !important;
    }
  }

  span {
    color: #ff8f00 !important;
  }

  .blinking-cursor {
    font-size: 6rem;
    color: #2c3e50;
    -webkit-animation: 1s blink step-end infinite;
    -moz-animation: 1s blink step-end infinite;
    -ms-animation: 1s blink step-end infinite;
    -o-animation: 1s blink step-end infinite;
    animation: 1s blink step-end infinite;
  }

  @keyframes blink {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #2c3e50;
    }
  }

  @-moz-keyframes blink {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #2c3e50;
    }
  }

  @-webkit-keyframes blink {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #2c3e50;
    }
  }

  @-ms-keyframes blink {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #2c3e50;
    }
  }

  @-o-keyframes blink {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #2c3e50;
    }
  }

</style>
